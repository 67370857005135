/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useFileUpload from 'hooks/FileUpload';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const DropSpace = styled(Box)({
    width: '100%',
    minWidth: '480px',
    height: '150px',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '20px',
    border: '1px dashed #1E49E2',
    color: '#1E49E2',
    fontSize: '18px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    // @media (maxWidth: '1200px'): {
    //   fontSize: '16px',
    // }
});

export default function DropzoneComponent({
    fileType,
    description,
    setHasFile,
    hasFile,
    setUploadedFile,
    uploadedFile,
    setFilesNda,
    uploadPath,
    setFileInputDropzone,
    setId,
    id,
    fileName
}) {
    const [dropzoneLoading, setDropzoneLoading] = useState(false);
    const { handleSubmit, files, setFiles } = useFileUpload();
    const location = useLocation()

    const removeFiles = () => {
        setHasFile(false);
        setFiles([]);
        setUploadedFile(false);
        if (uploadPath === 'confidencialidades') setFilesNda([]);
        if (uploadPath === 'contratos') setFileInputDropzone([]);
        if (uploadPath === 'ativos') setId('');
    };

    function verificationFile(uploadedFiles) {
        const arrayName = [];

        try {
            setDropzoneLoading(true);
            const filesName = files.map((f) => f.name);

            if (uploadedFiles[0].size > 209715200 && location.pathname === '/upload' || uploadedFiles[0].size > 41943040 && location.pathname !== '/upload') {
                setDropzoneLoading(false);
                return toast.error(`Tamanho máximo de ${location.pathname === '/upload' ? 200 : 40}MB excedido`);
            }

            const filesExists = uploadedFiles.filter((uF) => {
                if (filesName.includes(uF.name)) {
                    return uF;
                }
                return null;
            });

            if (filesExists.length >= 1) {
                setDropzoneLoading(false);
                toast.error('Não é possível enviar arquivos com mesmo nome.');
                return;
            }

            const invalidFiles = uploadedFiles.filter(
                (file) => !file.name.endsWith(fileType)
            );

            if (invalidFiles.length > 0) {
                setDropzoneLoading(false);
                return toast.error(`Selecione apenas arquivos em ${fileType}`);
            }

            if (arrayName.length >= 1) {
                setDropzoneLoading(false);
                return toast.error(
                    'Identificado o caracteres ponto no nome do arquivo. Por favor retirar!'
                );
            }

            setFiles([...files, ...uploadedFiles]);
            if (uploadPath === 'contratos') {
                setFileInputDropzone(uploadedFiles);
            } else if (uploadPath === 'confidencialidades') {
                setFilesNda(uploadedFiles);
            }
            setHasFile(true);
            setDropzoneLoading(false);
        } catch (error) {
            setHasFile(false);
            setDropzoneLoading(false);
            toast.error(error.response.data.message || error.message || error);
        }
    }
    return (
        <>
            {!hasFile && (
                <Dropzone
                    onDropAccepted={(selectedFiles) =>
                        verificationFile(selectedFiles)
                    }
                    // multiple
                    maxFiles={1}
                    onDropRejected={(err) => {
                        if (err[0].errors[0].message === 'Too many files') {
                            toast.error(
                                'Erro. Quantidade de arquivos excedido!'
                            );
                        }
                    }}
                    onErrorCaptured={() => {
                        toast.error('Erro. Tente novamente');
                    }}
                // disabled={!nameContainer}
                >
                    {({ getRootProps, getInputProps }) => (
                        <DropSpace {...getRootProps()}>
                            <input {...getInputProps()} accept={fileType} />
                            {!dropzoneLoading ? (
                                <>
                                    <img
                                        src='/static/image/Upload.svg'
                                        alt='Upload arquivo'
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: '18px',
                                            whiteSpace: 'nowrap',
                                        }}
                                        variant='h5'
                                        color='#959595'
                                    >
                                        {description}
                                        <Typography
                                            variant='inherit'
                                            color='#000'
                                        >
                                            realize o upload
                                        </Typography>
                                    </Typography>
                                </>
                            ) : (
                                <p>
                                    Carregando... O upload pode levar alguns
                                    minutos!
                                </p>
                            )}
                        </DropSpace>
                    )}
                </Dropzone>
            )}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {files.length > 0 ? (
                    files.map((item) => (
                        <Typography
                            key={item?.name}
                            variant='h5'
                            sx={{ margin: '8px 0' }}
                        >
                            {item?.name} || {item?.progress || 0} %
                        </Typography>
                    ))
                ) : (
                    <Typography variant='h5' sx={{ margin: '10px 0' }}>
                        Nenhum arquivo...
                    </Typography>
                )}
                {!uploadedFile && (
                    <Box>
                        {files.length > 0 && (
                            <Button
                                variant='outlined'
                                color='primary'
                                disabled={files.length <= 0 || files[0]?.progress }
                                size='8px'
                                sx={{
                                    marginBottom: '12px',
                                    marginRight: '14px',
                                }}
                                onClick={removeFiles}
                            >
                                Remover arquivo
                            </Button>
                        )}

                        <Button
                            variant='contained'
                            color='primary'
                            disabled={files.length <= 0 || files[0]?.progress }
                            size='8px'
                            sx={{
                                marginBottom: '12px',
                            }}
                            onClick={() =>
                                handleSubmit(setUploadedFile, uploadPath, setId,fileName)
                            }
                        >
                            Enviar Arquivo
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
}
