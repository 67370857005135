import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Resumable from 'resumablejs';

export default function useFileUpload() {
    const [files, setFiles] = useState([]);
    // const [contractUploaded, setContractUploaded] = useState(false);
    const location = useLocation();

    function handleSubmit(setUploadedFile, uploadPath, setId, fileName) {
        console.log('handleSubmit fileName', fileName)
        const resumable = new Resumable({
            target: `/api/blob/upload/${uploadPath}`,
            chunkSize: 2 * 1024 * 1024,
            simultaneousUploads: 1,
            testChunks: false,
            chunkRetryInterval: 4000,
            uploadMethod: 'POST',
            withCredentials: true,
            forceChunkSize: true,
            // prioritizeFirstAndLastChunk: true,
            query: { fileName },
            setChunkTypeFromFile: 'multipart/form-data',
            headers: { Authorization: localStorage.getItem('token') },
        });

        resumable.addFile(files[0]);

        resumable.on('fileAdded', async (file) => {
            const newFiles = [...files];
            newFiles[0] = file.uniqueIdentifier;
            resumable.upload();
        });

        resumable.on('fileProgress', async (file) => {
            const newFiles = [...files];
            const uploadPercentage = file.progress() * 100;
            newFiles[0].progress = uploadPercentage.toFixed(2);
            setFiles(newFiles);
        });

        resumable.on('fileSuccess', async (_file, message) => {
            setUploadedFile(true);
            if (location.pathname === '/upload') {
                setId(message);
            }
            // setHasFile(false)
            toast.success('Arquivo enviado com sucesso!');
        });

        resumable.on('fileError', async () => {
            toast.error('Erro ao enviar o arquivo!');
            // window.location.href = '/signout';
        });
    }

    return { handleSubmit, files, setFiles /* , contractUploaded */ };
}
